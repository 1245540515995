import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { s, colors, sCenteredSpaced } from '../style'
import Button from '../components/Button'
import Tiles from '../components/Tiles'
import Headline from '../components/Headline'
import Section from '../components/Section'
import { useFormContext } from '../context/form.context'


export default function SpeditionTemplate({ data }) {
  const {
    name,
    title,
    slug,
    keywords,
    image,
    body_1,
    body,
    description,
    createdAt,
    updatedAt,
    isoffice,
    city,
  } = data.spedition

  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const imageData = getImage(image)

  const links = data?.jobs?.nodes
    .filter((node) => node.node_locale === 'pl')
    .filter((node) => node.city === city)
    .map((node) => ({
      slug: 'kariera/' + node.slug,
      name: node.city,
      image: node.image,
      subtitle: node.title,
    }))
    
  const button = {
    text: 'Sprawdź cennik',
    link: '/sklep/',
    dimmed: true,
  }
  
  const buttonIntro = {
    text: 'Czytaj więcej',
    action: 'SCROLL',
  }

  const buttonQuote = {
    text: 'Wyceń kontener',
    action: () => setFormOpen(true),
    glow: true,
  }


  const buttonParent = {
    text: 'Więcej o depotach',
    link: '/depoty/',
    dimmed: true,
  }

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={keywords}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={description}
        image={imageData}
        button={button}
        buttonSecond={buttonIntro}
      />
      <TemplateBody
        title={description}
        body1={body_1.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        products={data.products}
        image={imageData}
        imageCredits={image?.description}
        buttons={[buttonParent, buttonQuote]}>
        {isoffice &&
          data?.offices?.nodes.map((office, id) => (
            <div css={sOfficeContainer} key={id}>
              <span>Omida VLS</span>
              <h2 css={sTitle}>
                {OFFICE(lang)} {office.name.replace('Spedycja', '')}
              </h2>
              <div>
                <a
                  href={`https://www.google.pl/maps/search/${office.address}`}
                  target='_blank'
                  rel='noreferrer noopener'>
                  {office.address}
                </a>
                <a href={`mailto:${office.email}`}>{office.email}</a>
                <a href={`tel:${office.phone}`}>{office.phone}</a>
              </div>
            </div>
          ))}
      </TemplateBody>
      {isoffice && (
        <>
          <Section top>
            <Headline h={2}>
              Aktualne oferty pracy w miejscowości {city}
            </Headline>
          </Section>
          <Tiles data={links} marginMinimal />
        </>
      )}
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    pl: 'Powrót do miejscowości',
    en: 'Back to spedition',
  },
  link: '/miejscowosci/',
}

const OFFICE = (lang) => (lang === 'en' ? 'Office' : 'Oddział')

const sOfficeContainer = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1.5rem',
  padding: '4rem',
  marginBottom: '4rem',
  [s.sm_down]: {
    borderRadius: '1rem',
    padding: '2rem',
  },
  backgroundColor: colors.text,

  span: {
    letterSpacing: '-0.05em',
    // opacity: 0.3,
    fontSize: '1rem',
    fontWeight: 700,

    color: colors.brand,
    [s.xs]: {
      paddingBottom: '0.5rem',
    },
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    a: {
      letterSpacing: '-0.025em',
      transition: 'opacity 0.3s ease-out',
      opacity: 0.6,
      fontWeight: 700,
      fontSize: '0.875rem',
      '&:hover': {
        opacity: 1,
      },
    },
    [s.xs]: {
      flexDirection: 'column',
      a: {
        fontSize: '1.125rem',
        marginBottom: '1rem',
      },
    },
  },
}

const sTitle = {
  fontSize: '3rem',
  [s.xs]: {
    fontSize: '2rem',
  },
}

export const query = graphql`
  query ($id: String!, $city: String) {
    spedition: contentfulLocation(id: { eq: $id }) {
      id
      createdAt
      updatedAt
      slug
      name
      title
      description
      keywords
      isoffice
      address
      email
      city
      body_1 {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
        file {
          url
          fileName
        }
      }
    }
    offices: allContentfulLocation(
      filter: { city: { eq: $city }, node_locale: { eq: "pl" } }
    ) {
      nodes {
        name
        isoffice
        address
        email
        city
      }
    }
    products: allShopifyProduct(
      #filter: {collections: {elemMatch: {id: {eq: $id}}}}
      filter: { id: { ne: $id } }
    ) #sort: { fields: [publishedAt], order: ASC }
    #limit: 24
    {
      nodes {
        ...ProductCard
      }
    }
  }
`
