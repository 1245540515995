import * as React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ProductListing } from '../components/ProductListing'
import CollectionLinks from '../components/shopify/collection-tags'
import { s, sHtml } from '../style'

export default function ProductCollection({
  data: { products, products2, collection },
  pageContext: { handle, title, id },
}) {

  const shopifyProductList = products.nodes;
  const contentfulProductList = products2.nodes.filter(item => item['orderNo'] !== null);

  const productList = shopifyProductList.map(shopifyProduct => ({
    ...shopifyProduct,
    ...contentfulProductList.find(contentfulProduct => contentfulProduct.slug === shopifyProduct.handle),
  })).sort((a, b) => a.orderNo - b.orderNo);

  const seoImagePath = getSrc(collection.image)
  const desc = collection?.descriptionHtml
  
  return (
    <Layout white>
      <Seo
        title={collection.seo.title}
        desc={collection.seo.description}
        imageUrl={seoImagePath}
        url={`sklep/` + handle}
        isCollection
      />
      <section css={sOuter}>
        <CollectionLinks />
        <h1 css={sHeadline}>{title}</h1>
        {desc !== undefined && desc !== "" && (
          <div css={[sHtml, sParagraph]} dangerouslySetInnerHTML={{ __html: desc }} />
        )}
        <ProductListing products={productList} />
      </section>
    </Layout>
  )
}


export const query = graphql`
  query ($id: String!) {
    products: allShopifyProduct(
      filter: {collections: {elemMatch: {id: {eq: $id}}}}
      #sort: { fields: [publishedAt], order: ASC }
      #limit: 24
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
    products2: allContentfulProdukt(
      sort: {order: ASC, fields: orderNo}
      filter: {node_locale: {eq: "pl"}}
    ) {
      nodes {
        slug
        orderNo
      }
    }
    collection: shopifyCollection(
      id: { eq: $id }
    ) {
      seo {
        description
        title
      }
      description
      descriptionHtml
      #image {
        #gatsbyImageData
      #}
    }
  }
`

const sOuter = {
  height: 'max-content',
  minHeight: '100vh',
  width: '100%',
  maxWidth: '1500px',
  position: 'relative',
  top: 0,
  left: 0,
  margin: '0 auto',
  padding: '12rem 2rem 10rem',
  [s.xs]: {
    padding: '8rem 2rem',
  },
}

const sParagraph = {
  'li': {
    fontSize: '1rem',
    marginBottom: '0.5rem',
    color: '#2b2a29'
  },
  'ul': {
    color: '#2b2a29'
  },
  'p + ul': {
    marginBottom: '1rem'
  },
  [s.md]: {
    fontSize: '1.15rem',
    padding: '0 2rem 0',
  },
}

const sHeadline = {
  margin: '0 auto 2rem',
  fontSize: '3rem',
  [s.md]: {
    padding: '0 2rem',
  },
}
