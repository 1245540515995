export const seo = {
  url: 'kontakt',
  title: {
    pl: 'Kontenery Morskie | Kontakt | Dystrybutor Kontenerów',
    en: 'Contact with the transport company. Experts in transport, forwarding and logistics',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: 'Get in touch with the logistics experts. More than 20 branches throughout Poland are responsible for transport throughout Europe.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Kontakt',
    en: 'Contact',
  },
  desc: {
    pl: `
    <span>
      <strong>OMIDA TRADE</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      Dane rejestrowe:</br>
      <strong>Omida VLS Sp. z o.o.</strong></br>
      NIP: 584 273 95 82 <br/>
      REGON: 360603722<br/>
      KRS: 0000459017<br/>
      <br/>
    </span>
    `,
    en: `
    <span>
      <strong>Omida VLS Sp. z o.o.</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      tel. +48 58 741 88 14</br>
      fax. +48 58 741 88 14<br/>
      <br/>
      NIP: 584 273 95 82 <br/>
      REGON: 360603722<br/>
      KRS: 0000459017<br/>
    </span>
    `,
  },
}

export const main = {
  title: {
    pl: 'Kontakt z Omida Trade',
    en: 'Contact with the transport company',
  },
  texts: [
    {
      pl: 'Nawiąż rozmowę z ekspertami w dziedzinie sprzedaż kontenerów. Skorzystaj z szybkiego zapytania i wyceń kontenery dla Twojego biznesu - wyświetl formularz kontaktowy poprzez przycisk po prawej stronie ekranu.',
      en: 'Begin a conversation with experts in the field of transport, forwarding and logistics. Find the right shipping department or branch. Use a quick inquiry, quote a transport, establish cooperation or join a network of carriers - display the forms by clicking the button on the right side of the screen.',
    },
  ],
}
