import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { s, colors, sCenteredSpaced } from '../style'
import Button from '../components/Button'
import { useFormContext } from '../context/form.context'

export default function DepotTemplate({ data }) {
  const {
    name,
    title,
    slug,
    keywords,
    image,
    body,
    description,
    createdAt,
    updatedAt,
  } = data.spedition

  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const imageData = getImage(image)

  const buttonIntro = {
    text: 'Czytaj więcej',
    action: 'SCROLL',
  }

  const buttonQuote = {
    text: 'Wyceń kontener',
    action: () => setFormOpen(true),
    glow: true,
  }

  const buttonParent = {
    text: 'Więcej o depotachi',
    link: '/depoty/',
    dimmed: true,
  }

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={keywords}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={description}
        image={imageData}
        button={buttonIntro}
      />
      <TemplateBody
        title={description}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        image={imageData}
        imageCredits={image?.description}
        buttons={[buttonParent, buttonQuote]}>
      </TemplateBody>
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    pl: 'Powrót do depotów',
    en: 'Back to spedition',
  },
  link: '/depoty/',
}

const OFFICE = (lang) => (lang === 'en' ? 'Office' : 'Oddział')

const sOfficeContainer = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1.5rem',
  padding: '4rem',
  marginBottom: '4rem',
  [s.sm_down]: {
    borderRadius: '1rem',
    padding: '2rem',
  },
  backgroundColor: colors.text,

  span: {
    letterSpacing: '-0.05em',
    // opacity: 0.3,
    fontSize: '1rem',
    fontWeight: 700,

    color: colors.brand,
    [s.xs]: {
      paddingBottom: '0.5rem',
    },
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    a: {
      letterSpacing: '-0.025em',
      transition: 'opacity 0.3s ease-out',
      opacity: 0.6,
      fontWeight: 700,
      fontSize: '0.875rem',
      '&:hover': {
        opacity: 1,
      },
    },
    [s.xs]: {
      flexDirection: 'column',
      a: {
        fontSize: '1.125rem',
        marginBottom: '1rem',
      },
    },
  },
}

const sTitle = {
  fontSize: '3rem',
  [s.xs]: {
    fontSize: '2rem',
  },
}

export const query = graphql`
  query ($id: String!) {
    spedition: contentfulDepot(id: { eq: $id }) {
      id
      createdAt
      updatedAt
      slug
      name
      title
      description
      keywords
      address
      city
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
        file {
          url
          fileName
        }
      }
    }
  }
`
